import { createRouter, createWebHashHistory } from "vue-router";
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      component: () => import("../components/login/login.vue"),
    },
    {
      path: "/home",
      component: () => import("../components/admin/home.vue"),
      redirect: "/version",
      children: [
        {
          path: "/splash",
          component: () => import("../components/admin/splash.vue"),
        },
        {
          path: "/ads",
          component: () => import("../components/admin/ads.vue"),
        },
        {
          path: "/host",
          component: () => import("../components/admin/host.vue"),
        },
        {
          path: "/token",
          component: () => import("../components/admin/token.vue"),
        },
        {
          path: "/app",
          component: () => import("../components/admin/app.vue"),
        },
        {
          path: "/banner",
          component: () => import("../components/admin/banner.vue"),
        },
        {
          path: "/pkg",
          component: () => import("../components/admin/pkg.vue"),
        },
        {
          path: "/girl",
          component: () => import("../components/admin/girl.vue"),
        },
        {
          path: "/notify",
          component: () => import("../components/admin/notify.vue"),
        },
        {
          path: "/share",
          component: () => import("../components/admin/share.vue"),
        },
        {
          path: "/version",
          component: () => import("../components/admin/version.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (!token && to.path != "/") {
    next("/");
  } else {
    next();
  }
});

export default router;
